import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
   en: {
      Managers: 'Managers',
      Search: 'Search',
      Sales: 'Sales',
      'Pending Sales Approval': 'Pending Sales Approval',
      'Top categories': 'Top Categories',
      'Enter Location': 'Enter Location',
      Location: 'Location',
      Filters: 'Filters',
      'Without Delivery': 'Without Delivery',
      store: 'Store',
      'Type Of Sellers': 'Type Of Sellers',
      'See more': 'See more',
      'See less': 'See less',
      Individual: 'Individual',
      Company: 'Company',
      cooperative: 'Cooperative',
      OPA: 'OPA',
      'Add to Cart': 'Add to Cart',
      'Proceed to buy': 'Proceed to buy',
      'Total Price': 'Total Price',
      Delivery: 'Delivery',
      Item: 'Item',
      'All Filters': 'All Filters',
      'Search For Actor': 'Actors directory',
      'Select a type actor': 'Select a type actor',
      'Type a message': 'Type a message',
      Comments: 'Comments',
      Edit: 'Edit',
      'Incorrect login': 'Incorrect login',
      Username: 'Username',
      Login: 'Login',
      'forgot password': 'forgot password',
      'Sign in to your account': 'Sign In to your account',
      'Forgot Password': 'Forgot Password',
      'Password will be send on registered email id':
         'Password will be send on registered email id',
      'To log in': 'Log in',
      admin: 'Admin',
      vendor: 'Vendor',
      Consumer: 'Consumer',
      'Sign Up': 'Sign Up',
      Id: 'Id',
      'View manager': 'View Manager',
      'View Product': 'View Product',
      'View Offer': 'View Offer',
      'View Forums': 'View Forums',
      Parameters: 'Parameters',
      'Edit Forums': 'Edit Forum',
      Unit: 'Unit',
      Currency: 'Currency',
      currency: 'currency',
      'Indian Rupee': 'Indian Rupee',
      Euro: 'KMF',
      Users: 'Users',
      Admin: 'Admin',
      kilogram: 'Kilogram',
      Liter: 'Litre',
      No: 'No',
      'Create new Message': 'Create New Message',
      'Please enter title': 'Please enter title',
      'Select image for forum': 'Select image for forum',
      'Please select delivery mode': 'Please select delivery mode',
      'Please select user': 'Please select user',
      'Select User': 'Select User',
      'select user field is required': 'Select user field is required',
      'Select a payment method': 'Select a payment method',
      'Please select a payment method': 'Please select a payment method',
      'Approve Order': 'Approve Order',
      'Payment Type': 'Payment Type',
      Languages: 'Languages',
      Logout: 'Logout',
      Settings: 'Settings',
      'Change Password': 'Change Password',
      'Update Profile': 'Update Profile',
      'Chat with seller': 'Chat with a seller',
      Home: 'Home',
      Dashboard: 'Dashboard',
      'Manage Users': 'Manage Users',
      Products: 'Products',
      Orders: 'Orders',
      Posts: 'Posts',
      User: 'User',
      'Buyer Sub-profile': 'Reseller Sub-profile',
      Update: 'Update',
      Delete: 'Delete',
      Confirm: 'Confirm',
      Submit: 'Submit',
      Save: 'Save',
      Reset: 'Reset',
      Cancel: 'Cancel',
      Next: 'Next',
      Previous: 'Previous',
      Refresh: 'Refresh',
      'Ready To Vote': 'Ready To Vote',
      Success: 'Success',
      Error: 'Error',
      Offer: 'Offer',
      'Add Offer': 'Add Offer',
      'Offer Name': 'Offer Name',
      'Please enter offer name': 'Please enter offer name',
      'Short Offer Description': 'Short Offer Description',
      'Please enter offer description': 'Please enter offer description',
      'Select Product': 'Select Product',
      'Please select product': 'Please select product',
      'Edit Offer': 'Edit Offer',
      'Delete Offer': 'Delete Offer',
      'Are you sure you want to delete offer': 'Are you sure you want to delete offer',
      'Offer successfully update': 'Offer successfully update',
      'Offer successfully save': 'Offer successfully save',
      'Pending Product Approval': 'Pending Product Approval',
      'Pending Products Approval': 'Pending Product Approval',
      'Pending Offer Approval': 'Pending Offer Approval',
      'Approve Product': 'Approve Product',
      'Product approved successfully': 'Product approved successfully',
      'Offer approved successfully.': 'Offer approved successfully.',
      approve_offer: 'Approve Offer',
      'Quality Indicator': 'Quality Indicator',
      Excellent: 'Excéllent',
      Good: 'Bon',
      Bleu: 'Bleu',
      Medium: 'Medium',
      'Please select product indicator by visiting the view option.':
         'Please select product indicator by visiting the view option.',
      'Start from': 'Start from',
      'No offers available': 'No offers available',
      Forums: 'Forums',
      forums: 'forums',
      'Create forums': 'Create Forums',
      'Delete Forums': 'Delete Forums',
      'Are you sure you want to delete forums': 'Are you sure you want to delete forums',
      'Forums Title': 'Forums Title',
      'Please enter forums title': 'Please enter forums title',
      Description: 'Description',
      'Please enter description': 'Please enter description',
      'Forums successfully update': 'Forums successfully update',
      'Forums successfully save': 'Forums successfully save',
      'Forums Picture': 'Forums Picture',
      Title: 'Title',
      'Forums Description': 'Forums Description',
      'Are you sure you want to delete comment':
         'Are you sure you want to delete comment',
      'Delete Comment': 'Delete Comment',
      'Edit Comment': 'Edit Comment',
      Comment: 'Comment',
      Post: 'Post',
      Name: 'Name',
      'Please enter name': 'Please enter name',
      'Sub-profile': 'Sub-profile',
      'You can only upload images': 'You can only upload images',
      'Around me': 'Around me',
      Clear: 'Clear',
      'Create Forums': 'Create Forums',
      'Write your comment here': 'Write your comment here',
      'Upload document': 'Upload document',
      'Profile Updated Successfully': 'Profile Updated Successfully',
      Address: 'Address',
      City: 'City',
      Action: 'Action',
      'PIN Code': 'PIN Code',
      'Number Of Apartments': 'Number Of Apartments',
      'Are you sure you want to delete store': 'Are you sure you want to delete store',
      'Are you sure you want to delete product':
         'Are you sure you want to delete product',
      'Please enter company name': 'Please enter company name',
      'Please enter address': 'Please enter address',
      'Please enter country': 'Please enter country',
      'Please enter activity description': 'Please enter activity description',
      'Please enter needs description': 'Please enter needs description',
      'No Records Found.': 'No Records Found',
      'Please enter birth date': 'Please enter birth date',
      'Please enter land amount': 'Please enter land amount',
      'Land Amount': 'Land Amount',
      'Land Amount Measure': 'Land Amount Measure',
      Type: 'Type',
      'Creation Date': 'Creation Date',
      'Please enter creation date': 'Please enter creation date',
      "Sorry, we don't have that on hand!": "Sorry, we don't have that on hand!",
      'You deserve so much more than a fruitless search!':
         'You deserve so much more than a fruitless search!',
      'What if you changed category? You can also search within the category family to give you a better chance of obtaining a result.':
         ' What if you changed category? You can also search within the category family to give you a better chance of obtaining a result.',
      'Your order has been placed': 'Your order has been placed',
      'Order Date': 'Order Date',
      'Show Details': 'Show Details',
      'Hide Details': 'Hide Details',
      Warning: 'Warning,',
      Payment: 'Payment',
      'Payment Status': 'Payment Status',
      'Payment Details': 'Payment Details',
      'Store Advertisement': 'Store Advertisement',
      'Store Image': 'Store Image',
      Quantity: 'Quantity',
      'Product Catalogue Name': 'Product Catalogue Name',
      Directory: 'Directory',
      'Picture or Video': 'Picture or Video',
      'Please select picture or video': 'Please select picture or video',
      'Please Enter Advertisement Type': 'Please Enter Advertisement Type',
      'Post Description': 'Post Description',
      'Add Posts': 'Add Post',
      'Delete Posts': 'Delete Post',
      'Posted On': 'Posted On',
      'Please enter IBAN': 'Please Enter IBAN',
      'Are you sure you want to Enable/Disable product':
         'Are you sure you want to Enable/Disable product',
      'Disable/Enable Product': 'Disable/Enable Product',
      'Enable Product': 'Enable Product',
      'Disable Product': 'Disable Product',
      'Product Disabled Successfully Saved': 'Product Disabled Successfully Saved',
      'Product Enabled Successfully Saved': 'Product Enabled Successfully Saved',
      'Please Enter BIC': 'Please Enter BIC',
      'Please Enter Account Owner': 'Please Enter Account Owner',
      'Upload Profile Photo': 'Upload Profile Photo',
      'Please Enter Account Address': 'Please Enter Account Address',
      'Manage Posts': 'Manage Posts',
      'Number Of Comments': 'Number Of Comments',
      'Please Enter Store URL': 'Please Enter Store URL',
      'Store URL': 'Store URL',
      'Please Assign Trader to Store': 'Please Assign Trader to Store',
      'After Saved Store Please, Go to backOffice User and assign Trader to Store for Enable store':
         'After Saved Store Please, Go to backOffice User and assign Trader to Store for Enable store',
      'Are you sure you want to delete Payment Details':
         'Are you sure you want to delete Payment Details',
      'Number Of Likes': 'Number Of Likes',
      'Post Saved Successfully': 'Post Saved Successfully',
      'Please Enter Post Description': 'Please Enter Post Description',
      'Are you sure you want to Enable/disable store':
         'Are you sure you want to Enable/disable store',
      'Are You Sure You Want To Delete Posts': 'Are You Sure You Want To Delete Posts',
      'Please enter Product Type': 'Please enter Product Type',
      'Advertising Products': 'Advertising Products',
      'Add Advertisement': 'Add Advertisement',
      'Edit Advertisement': 'Edit Advertisement',
      'Delete Ads': 'Delete Ads',
      'Are you sure you want to delete Ads': 'Are you sure you want to delete Ads',
      'Item Name': 'Item Name',
      Photos: 'Photos',
      'Advertisement Type': 'Advertisement Type',
      'Please enter price': 'Please enter price',
      Price: 'Price',
      None: 'None',
      none: 'none',
      'Enter Store Image': 'Enter Store Image',
      'Add Pictures :': 'Add Pictures :',
      'Item Description': 'Item Description',
      'Please enter item name': 'Please enter item name',
      'Please enter item description': 'Please enter item description',
      Consumers: 'Consumers',
      'Add Users': 'Add Users',
      'Are You Sure You Want To Delete User': 'Are You Sure You Want To Delete User',
      'Delete User': 'Delete User',
      'Store Already assigned to': 'Store Already assigned to',
      'Enable Store': 'Enable Store',
      Password: 'Password',
      'Reset Password': 'Reset password',
      'Enable/Disable of User': 'Enable/Disable of User',
      'Confirm Password': 'Confirm Password',
      'Please enter confirm password': 'Please enter confirm password',
      'Please enter password': 'Please enter password',
      'Disable User': 'Disable User',
      'Account Owner': 'Account Owner',
      Latitude: 'Latitude',
      Longitude: 'Longitude',
      'Select all': 'Select all',
      'Unselect all': 'Unselect all',
      Role: 'Role',
      'Order Items': 'Order Items',
      'Flash Sale': 'Flash Sale',
      'Account Address': 'Account Address',
      'Show In Flash Sale': 'Show In Flash Sale',
      'Show In': 'Show In',
      Administrator: 'Administrator',
      Trader: 'Trader',
      'disable User': 'disable User',
      'enable User': 'Enable User',
      'Approve User': 'Approve User',
      'Delivery Mode': 'Delivery Mode',
      'Store Manager': 'Store Manager',
      'Store Info': 'Store Info',
      'Home Page': 'Home Page',
      'Product Types': 'Product Types',
      'Select Manager': 'Select Manager',
      'Choose a Picture or Video': 'Choose a Picture or Video',
      'Edit Store Advertisement': 'Edit Store Advertisement',
      'Please fill Delivery Price': 'Please fill Delivery Price',
      'Please fill Minimum Delivery': 'Please fill Minimum Delivery',
      'Please select manager': 'Please select manager',
      'Physical Store Does not exist': 'Physical Store Does not exist',
      'Add User': 'Add User',
      'Add Product': 'Add Product',
      'Store Image URL': 'Store Image URL',
      'Password successfully reset': 'Mot de passe réinitialisé avec succès',
      'Add Product Group': 'Add Product Group',
      'Please log in via the mobile app':
         "Veuillez vous connecter via l'application mobile",
      'Select Product Type': 'Select Product Type',
      'Please select product group': 'Please select product group',
      'Select Product Group': 'Select Product Group',
      'Password reset successfully': 'Mot de passe réinitialisé avec succès',
      'Please select product type': 'Please select product type',
      'Group Name': 'Group Name',
      'Product Name': 'Product Name',
      'Store Status': 'Store Status',
      'You will receive a reset password Link on your Mail ID':
         'You will receive Reset Password Link on your Mail ID',
      'Total Quantity': 'Total Quantity',
      'Total Quantity Unit': 'Total Quantity Unit',
      'Active Status': 'Active Status',
      'Department Name': 'Department Name',
      'Order Details': 'Order Details',
      'Available Quantity': 'Available Quantity',
      'Available Quantity Unit': 'Available Quantity Unit',
      'Product Successfully Saved': 'Product Successfully Saved',
      'Product Successfully Update': 'Product Successfully Update',
      'Sub-profile saved successfully.': 'Sub-profile saved successfully.',
      'Sub-profile updated successfully.': 'Sub-profile updated successfully.',
      'The Product Picture field is required': 'The Product Picture field is required',
      'Add Store Advertisement': 'Add Store Advertisement',
      'Please enter available quantity': 'Please enter available quantity',
      'Please enter total quantity': 'Please enter total quantity',
      'Please enter unit': 'Please enter unit',
      'Type Of Product / Price': 'Type Of Product / Price',
      'Product short description': 'Product short description',
      'Order Delivery Address': 'Order Delivery Address',
      'Product long description': 'Product long description',
      'Please enter product long description': 'Please enter product long description',
      'Please enter short description': 'Please enter short description',
      'Please enter group name': 'Please enter group name',
      'Please enter product name': 'Please enter product name',
      'Advertising Products Pictures': 'Advertising Products Pictures',
      'Choose advertising Images': 'Choose advertising Images',
      'Are you sure you want to Change Status of':
         'Are you sure you want to Change Status of ',
      'Are you sure you want to approve of': 'Are you sure you want to approve of ',
      'Please select store': 'Please select store',
      'Please select product quality': 'Please select product quality',
      'Select Store': 'Select Store',
      'Edit Users': 'Edit Users',
      'Delete Account': 'Delete Account',
      Email: 'Email',
      'Please enter email': 'Please enter email..',
      Phone: 'Telephone',
      'Activity Description': `Activity Description`,
      'Needs Description': 'Needs Description',
      'Company Name': 'Company Name',
      'Download the group illustration photo': 'Download the group illustration photo',
      'Date of Birth': 'Date of Birth',
      'Street Name': 'Street Name',
      Amount: 'Amount (€)',
      Department: 'Department',
      'Is Physical Store ?': 'Is Physical Store ?',
      'Select Department': 'Select Department',
      'Check Details': 'Check Details',
      'Please select department': 'Please select department',
      'Disable Store': 'Disable Store',
      'Enable/Disable Store': 'Enable/Disable Store',
      'Categories Stores': 'Categories Stores',
      'Principal Address': 'Principal Address',
      'Other Address': 'Other Address',
      'Store Logo': 'Store Logo',
      'Company Logo': 'Company Logo',
      'Reformulate the search': 'Reformulate the search',
      'Postal Code': 'Postal Code',
      'Add Payment Info': 'Add Payment Info',
      'Types Of Products': 'Types Of Products',
      'Clothing / Fashion': 'Clothing / Fashion',
      'Beauty / Health': 'Beauty / Health',
      'Cultural Products': 'Cultural Products',
      'House, decoration': 'House, decoration',
      'Travel / Tourism': 'Travel / Tourism',
      'Games toys': 'Games toys',
      'Order ID': 'Order ID',
      Organic: 'Organic',
      Shoes: 'Shoes',
      'Store Location': 'Store Location',
      'Technical products / appliances': 'Technical products / appliances',
      'Articles, sports equipment': 'Articles, sports equipment',
      'Background Photo': 'Background Photo',
      'Please enter product amount': 'Please enter product amount',
      'Please enter product promo price': 'Please enter product promo price',
      'Please enter product sell price': 'Please enter product sell price',
      'Please select types of products': 'Please select types of products',
      'Select Type': 'Select Type',
      'Please select categories stores': 'Please select categories stores',
      'Please select background photo': 'Please select background photo',
      'Promo Price Should be less than Sell Price':
         'Promo Price Should be less than Sell Price',
      'Please select store logo': 'Please select store logo',
      'ProductGroup Deleted Successfully': 'ProductGroup Deleted Successfully',
      'Please select date of birth': 'Please select date of birth',
      'Please enter city': 'Please enter city',
      'Please enter Country.': 'Please enter Country.',
      'Please enter type': 'Please enter type',
      'Store Status Updated Successfully Saved':
         'Store Status Updated Successfully Saved',
      'Please enter postal code': 'Please enter postal code',
      'Please enter mobile number..': 'Please enter mobile number..',
      'Please fill StoreLink': 'Please fill StoreLink',
      'Please enter first name..': 'Please enter first name..',
      'Please enter store name..': 'Please enter store name..',
      Presentation: 'Presentation',
      Inactive: 'Inactive',
      Symbol: 'Symbol',
      'Please enter store presentation..': 'Please enter store presentation..',
      'Please enter last name..': 'Please enter last name..',
      'Please select buildings..': 'Please select buildings..',
      'Please select profile picture..': 'Please select profile picture..',
      'Choose a user profile picture..': 'Choose a user profile picture..',
      'Choose a Group Profile': 'Choose a Group Profile',
      'User Deleted Successfully': 'User Deleted Successfully',
      'Drop file here..': 'Drop file here..',
      'Manager updated successfully': 'Manager updated successfully',
      'Product Deleted Successfully': 'Product Deleted Successfully',
      'Manager saved successfully': 'Manager saved successfully',
      'Manager deleted successfully': 'Manager deleted successfully',
      'Add Advertising Products Pictures': 'Add Advertising Products Pictures',
      'User Data Successfully Saved': 'User Data Successfully Saved',
      'User Profile Successfully Saved': 'User Profile Successfully Saved',
      'Selected manager assigned somewhere': 'Selected manager assigned somewhere',
      'Are you sure you want to delete account':
         ' Are you sure you want to delete account',
      'Are you sure you want to delete': 'Are you sure you want to delete ',
      'First Name': 'First Name',
      'Last Name': 'Last Name',
      'View Store': 'View Store',
      View: 'View',
      'Product Picture': 'Product Picture',
      'Profile Picture': 'Profile Picture',
      Enabled: 'Enabled',
      Disabled: 'Disabled',
      'Please select product picture': 'Please select product picture',
      'Select Country Code': 'Select country code',
      'Please enter buy quantity from': 'Please enter buy quantity from',
      'Please enter buy quantity to': 'Please enter buy quantity to',
      'Buy Quantity From': 'Buy Quantity From',
      'Buy Quantity To': 'Buy Quantity To',
      'Add Row': 'Add Row',
      'Enter Actor Name': 'Enter Actor Name',
      'Please enter street name': 'Please enter street name',
      'Please enter store description': 'Please enter store description',
      'Delivery Price': 'Delivery Price',
      'Please enter delivery price': 'Please enter delivery price',
      'Show On Home Page?': 'Show On Home Page?',
      'is active': 'is active',
      Suggestions: 'Suggestions',
      'Shipping cost': 'Shipping cost',
      'Please enter shipping cost': 'Please enter shipping cost',
      'Category Type': 'Category Type',
      'Please select category type': 'Please select category type',
      'Minimum Price For Delivery': 'Minimum Price For Delivery',
      'Store Disabled Successfully Saved': 'Store Disabled Successfully Saved',
      'Store Enabled Successfully Saved': 'Store Enabled Successfully Saved',
      'Please enter minimum price for delivery':
         'Please enter minimum price for delivery',
      'User Disabled Successfully Saved': 'User Disabled Successfully Saved',
      'User Enabled Successfully Saved': 'User Enabled Successfully Saved',
      'Created By': 'Created By',
      'Created Date': 'Created Date',
      'Declared By': 'Declared By',
      'Check Address Tab': 'Check Address Tab',
      Response: 'Response',
      'Please select incident status': 'Please select incident status',
      History: 'History',
      'Please enter comment': 'Please enter comment',
      country: 'Country',
      'Pending Approval': 'Pending Approval',
      'Pending User Approval': 'Pending User Approval',
      'Product Groups': 'Product Groups',
      Copy: 'Copy',
      'My Profile': 'My Profile',
      'Advertisement Name': 'Advertisement Name',
      'Please Enter Proper Store Link': 'Please Enter Proper Store Link',
      'Please enter Advertisement Url': 'Please enter Advertisement Url',
      'Please enter Advertisement Date': 'Please enter Advertisement Date',
      'Advertisement Url': 'Advertisement Url',
      Stores: 'Stores',
      'New password': 'nouveau mot de passe',
      'Add Store': 'Add Store',
      'Edit Store': 'Edit Store',
      'Edit Product Groups': 'Edit Product Groups',
      'Edit Product': 'Edit Product',
      Status: 'Status',
      'Delete Store': 'Delete Store',
      'Delete Product': 'Delete Product',
      'Phone Number': 'Phone Number',
      'Loading...': 'Loading...',
      Date: 'Date',
      'Store Name': 'Store Name',
      'Sub Profile Management': 'Sub Profile Management',
      'Add Sub-profile': 'Add Sub-profile',
      'Sub-profileName': 'Sub-profileName',
      'Please enter Sub-profileName': 'Please enter Sub-profileName',
      'Edit Sub-profile': 'Edit Sub-profile',
      'Delete Sub-profile': 'Delete Sub-profile',
      'Are you sure you want to delete sub-profile':
         'Are you sure you want to delete sub-profile ',
      'Sub-profileDeleted Successfully': 'Sub-profileDeleted Successfully',
      'Save Search': 'Save Search',
      'Email id already exists': 'Email id already exists',
      'Mobile Number Should be 10 Digit': 'Mobile Number Should be 10 Digit',
      'Data not inserted properly': 'Data not inserted properly',
      'Password and confirm password does not match':
         'Password and confirm password does not match',
      'Please enter first name': 'Please enter first name',
      'Please enter last name': 'Please enter last name',
      'Department  Already exists': 'Department  Already exists',
      'Token not found': 'Token not found',
      'Please select user role': 'Please select user role',
      'User not active': 'User not active',
      'Successfully Login!!!': 'Successfully Login!!!',
      'Successfully Sign Up!!!': 'Successfully Sign Up!!!',
      'Please Enter Correct Username and Password':
         'Please Enter Correct Username and Password',
      'User id does not exist': 'User id does not exist',
      'Token Expired': 'Token Expired!',
      'Product Image Deleted Successfully Saved':
         'Product Image Deleted Successfully Saved',
      'Email Sent. Check your inbox.': 'Email Sent. Check your inbox.',
      'Email Does not Exists': 'Email Does not Exists',
      'Store Advertisement Successfully': 'Store Advertisement Successfully',
      'Please enter a store name': 'Please enter a store name',
      'Store Deleted Successfully': 'Store Deleted Successfully',
      'The user has not been approved': 'The user has not been approved',
      'Are you sure you want to refuse?': 'Are you sure you want to refuse?',

      'Please enter store phone no': 'Please enter store phone no',
      'Please enter store delivery price': 'Please enter store delivery price',
      'Search Forum': 'Search Forum',
      'Product Group Successfully Saved': 'Product Group Successfully Saved',
      'Post Document Deleted Successfully': 'Post Document Deleted Successfully',
      'An error occurred while deleting the user':
         'An error occurred while deleting the user',
      'An error occurred': 'An error occurred',
      'Pay Online': 'Pay Online',
      'Pay later': 'Pay later',
      'Partial payment': 'Partial payment',
      'An error occurred while deleting': 'An error occurred while deleting',
      'Store Successfully Saved': 'Store Successfully Saved',
      'enter IBAN': 'Enter IBAN',
      'The entire country': 'The entire country',
      'French Name': 'French Name',
      'Super Admin': 'Super Admin',
      Manager: 'Manager',
      Seller: 'Seller',
      Technician: 'Technician',
      Reseller: 'Reseller',
      'CRDE-Technician': 'CRDE-Technician',
      'Chambre de commerce-Technician': 'Chambre de commerce-Technician',
      "Chambre de l'Agriculture-Technician": "Chambre de l'Agriculture-Technician",
      Farmers: 'Farmers',
      Suppliers: 'Suppliers',
      Transformers: 'Transformers',
      Transporter: 'Transporter',
      'Partially Paid': 'Partially Paid',
      Paylater: 'Paylater',
      N: 'N',
      Total_Amount: 'Total Amount(€)',
      Promo_Price: 'Promo Price(€)',
      Sell_Price: 'Sell Price(€)',
   },
   fr: {
      'Super Admin': 'Super Administrateur',
      Manager: 'Gestionnaire',
      Seller: 'Vendeur',
      Technician: 'Technicien',
      Reseller: 'Revendeur',
      'CRDE-Technician': 'CRDE-Technicien',
      'Chambre de commerce-Technician': 'Chambre de commerce-Technicien',
      "Chambre de l'Agriculture-Technician": "Chambre de l'Agriculture-Technicien",
      Farmers: 'Exploitant Agricole',
      Suppliers: 'Fournisseurs',
      Transformers: 'Transformateurs',
      Transporter: 'Transporteur',
      Search: 'Recherche',
      'Top categories': 'Top catégories',
      'Enter Location': `Saisir le lieu`,
      Filters: 'Filtres',
      'Without Delivery': 'Sans livraison',
      Store: 'Magasin',
      'Type Of Sellers': 'Type de vendeurs',
      'See more': 'Voir plus',
      'See less': 'Voir moins',
      Individual: 'Individuel',
      Company: 'Entreprise',
      cooperative: 'Coopérative',
      OPA: 'OPA',
      'Add to Cart': 'Ajouter au panier',
      'Proceed to buy': `Passer la commande`,
      'Total Price': 'Prix total',
      Delivery: 'Livraison',
      Item: 'Objet',
      'All Filters': 'Tous les filtres',
      'Search For Actor': 'Annuaire des acteurs',
      'Select a type actor': `Sélectionner un type d'acteur`,
      'Type a message': 'Saisir un message',
      Comments: 'commentaires',
      Edit: 'Éditer',
      'Incorrect login': 'Identifiants incorrects',
      Username: "Nom d'utilisateur",
      Login: 'Se connecter',
      'forgot password': 'mot de passe oublié',
      'Sign in to your account': 'Se connecter',
      'Password will be send on registered email id':
         "Le mot de passe sera envoyé à l'adresse email enregistrée",
      'Forgot Password': 'Mot de passe oublié',
      'To log in': 'Se connecter',
      admin: 'Administrateur',
      vendor: 'vendeur',
      Consumer: 'Consommateur',
      Vendor: 'Vendeur',
      consumer: 'Consumer',
      'Sign Up': `S'inscrire`,
      Id: 'Id',
      Sales: 'Vente',
      'View manager': 'Voir le gestionnaire',
      'View Product': 'Voir le produit',
      'View Offer': `Voir l'offre`,
      'View Forums': 'Voir les forums',
      'Edit Forums': `Modifier l'forum`,
      Unit: 'Unité',
      Currency: 'Devise',
      currency: 'devise',
      'Indian Rupee': 'Roupie indienne',
      Euro: `L'euro`,
      Users: 'Utilisateurs',
      Admin: 'Administrateur',
      kilogram: 'kilogramme',
      Liter: 'Littéraire',
      No: 'Non',
      Home: 'Accueil',
      Dashboard: 'Tableau de bord',
      'Manage Users': 'Gestion des utilisateurs',
      Products: 'Produits',
      'Product Groups': 'Groupe de produits',
      Orders: 'Commandes',
      Posts: 'Des postes',
      User: 'Utilisateur',
      'Buyer Sub-profile': 'Sous-profil Revendeur',
      Members: 'Mes clients', //Mes propriétaires
      Incident: "Mon suivi d'incidents",
      'My Service Providers': 'Mes Prestataires',

      Languages: 'Langues',
      Profile: 'Profil',
      Logout: 'Déconnexion',
      Settings: 'Paramètres',
      'Chat with seller': 'Message au  vendeur',
      Update: 'Mise à jour',
      Delete: 'Supprimer',
      Confirm: 'Confirmer',
      Submit: 'Soumettre',
      Save: 'Valider',
      Cancel: 'Annuler',
      Next: 'Suivant',
      Previous: 'Précédent',
      Refresh: 'Rafraîchir',
      'Ready To Vote': 'Prêt à voter',
      Success: 'Succès',
      Error: 'Erreur',
      Offer: 'Offre',
      'Add Offer': 'Ajouter une offre',
      'Add Sales': 'Ajouter une vente',
      'Sales Name': 'Nom de la vente',
      'Offer Name': "Nom de l'offre",
      'Please enter offer name': `Veuillez saisir le nom de l'offre`,
      'Short Offer Description': `Brève description de l'offre`,
      'Please enter offer description': `Veuillez saisir la description de l'offre`,
      'Select Product': 'Sélectionner un produit',
      'Please select product': 'Veuillez sélectionner un produit',
      'Edit Offer': `Modifier l'offre`,
      'Delete Offer': `Supprimer l'offre`,
      'Are you sure you want to delete offer': `Êtes-vous sûr de vouloir supprimer l'offre`,
      'Offer successfully update': `Offre mise à jour réussie`,
      'Offer successfully save': `Sauvegarder l'offre avec succès`,
      'Pending Product Approval': `Produits en attente de validation`,
      'Pending Products Approval': `Produits en attente de validation`,
      'Pending Offer Approval': 'Offres en attente de validation',
      'Pending Sales Approval': 'Vente en attente de validation',
      'Approve Product': 'Approuver le produit',
      'Product approved successfully': 'Produit approuvé avec succès',
      'Offer approved successfully.': 'offre approuvée avec succès',
      'Quality Indicator': 'Indicateur de qualité',
      Excellent: 'Excéllent',
      Good: 'Bon',
      'this user is not a vendor': "cet utilisateur n'est pas un vendeur",
      Medium: 'Moyen',
      'Same password': 'Même mot de passe',
      approve_offer: "Approuver l'offre",
      'Start from': 'Commencer à partir de',
      'No offers available': 'Aucune offre disponible',
      'Please select product indicator by visiting the view option.':
         "Veuillez sélectionner l'indicateur de produit en visitant l'option d'affichage.",
      'Please enter company name': `Veuillez saisir le nom de l'entreprise`,
      'Please enter address': `Veuillez saisir l'adresse`,
      'Please enter country': 'Veuillez saisir le pays',
      'Please enter activity description': `Veuillez saisir la description de l'activité`,
      'Please enter needs description': 'Veuillez saisir la description des besoins',
      'No Records Found.': `Aucun enregistrement trouvé `,
      'Please enter birth date': 'Veuillez saisir la date de naissance',
      'Please enter land amount': 'Veuillez saisir le montant du terrain',
      'Land Amount': 'Montant du terrain',
      'Land Amount Measure': 'Mesure de la superficie du terrain',
      Type: 'Type',
      'Loading...': 'Chargement...',
      'Creation Date': 'Date de création',
      'Please enter creation date': 'Veuillez saisir la date de création',
      "Sorry, we don't have that on hand!": "Désolé, nous ne l'avons pas sous la main !",
      'You deserve so much more than a fruitless search!':
         "Vous méritez bien plus qu'une recherche infructueuse !",
      'What if you changed category? You can also search within the category family to give you a better chance of obtaining a result.':
         "Et si vous changiez de catégorie ? Vous pouvez également effectuer une recherche au sein de la famille de catégories pour avoir plus de chance d'obtenir un résultat.",
      forums: 'Forums',
      'Enter Card Details': 'Entrer les détails de la carte',
      'Make a payment': 'Effectuer un paiement',

      'The payment was refused': 'Le paiement a été refusé',
      'Your order has been cancelled': 'Votre commande a été annulé',
      'Your order has been taken into account': 'Votre commande a été prise en compte',
      'Create forums': 'Créer des forums',
      'Delete Forums': 'Supprimer les forums',
      'Are you sure you want to delete forums':
         'Êtes-vous sûr de vouloir supprimer les forums',
      'Forums Title': 'Titre du forum',
      'Please enter forums title': 'Veuillez saisir le titre du forum',
      Description: 'Description',
      'Please enter description': 'Veuillez saisir la description',
      'Forums successfully update': 'Mise à jour réussie des forums',
      'Forums successfully save': 'Sauvegarde réussie des forums',
      'Forums Picture': 'Photo du forum',
      Title: 'Titre',
      'Forums Description': 'Description du forum',
      'Search Forum': 'Recherche du forum',
      'Are you sure you want to delete comment':
         'Êtes-vous sûr de vouloir supprimer un commentaire',
      'Delete Comment': 'Supprimer le commentaire',
      'Edit Comment': 'Modifier le commentaire',
      Comment: 'Commentaire',
      Post: 'Post',
      Parameters: 'Paramètres',
      'Your order has been placed': 'Votre commande est passée',
      Name: 'Nom',
      'Please enter name': 'Veuillez saisir le nom',
      'Sub-profile': 'Sous-profil',
      'You can only upload images': 'Vous ne pouvez télécharger que des images',
      'Around me': 'Autour de moi',
      Clear: 'Effacer',
      'Maiden name': 'Nom de jeune fille',
      Symbol: 'Symbole',
      Suggestions: 'Suggestions',
      'Create Forums': 'Créer des forums',
      'Write your comment here': 'Écrivez votre commentaire ici',
      'Upload document': 'Télécharger un document',
      'Create new Message': 'Créer un nouveau message',
      'Please enter title': 'Veuillez saisir le titre',
      'Please select user': `Veuillez sélectionner l'utilisateur`,
      'Select User': `Sélectionner l'utilisateur`,
      'select user field is required': `Sélectionner le champ de l'utilisateur est obligatoire`,
      Email: 'Email',
      'The entire country': 'Tout le pays',
      'Are you sure you want to delete store':
         'Êtes-vous sûr de vouloir supprimer le magasin?',
      'Are you sure you want to delete product':
         'Êtes-vous sûr de vouloir supprimer le produit?',
      Address: 'Adresse',
      City: 'Ville',
      Action: 'Action',
      'Order Date': 'Date de la commande',
      'Reformulate the search': 'Reformuler la recherche',
      'Show Details': 'Afficher les détails',
      'Hide Details': 'Cacher les détails',
      Payment: 'Paiement',
      'Payment Status': 'Statut de paiement',
      'Partially Paid': ' Partiellement payé',
      Paylater: 'Paiement ultérieur',
      'French Name': 'Nom en français',
      N: 'N',
      'Payment Details': 'Détails de paiement',
      'Store Advertisement': 'Publicité dans les magasins',
      Directory: 'Annuaire',
      'Store Image': 'Image de magasin',
      Quantity: 'Quantité',
      'Product Catalogue Name': 'Nom du catalogue de produits',
      'My Profile': 'Votre profil',
      'Change Password': 'Changer le mot de passe',
      'Update Profile': 'Mettre à jour',
      'Select a payment method': 'Sélectionner un mode de paiement',
      'Please select a payment method': 'Veuillez sélectionner un mode de paiement',
      'Approve Order': 'Validation de la commande',
      'Payment Type': 'Type de paiement',
      'Picture or Video': 'Image ou vidéo',
      'Please select picture or video': 'Veuillez sélectionner une photo ou une vidéo',
      'Post Description': 'Description du poste',
      'Add Posts': 'Ajouter des messages',
      'Delete Posts': 'Supprimer les messages',
      'Posted On': 'Posté sur',
      'Are you sure you want to refuse?': 'Êtes-vous sûr de vouloir refuser ?',
      'Deny User': "Refuser l'utilisateur",
      'Manage Posts': 'Gérer les messages',
      'Department Name': 'Nom du département',
      'Post Document Deleted Successfully':
         'Suppression réussie du document de post-production',
      'Please Assign Trader to Store': 'Veuillez assigner un commerçant à la boutique',
      'Store Successfully Saved': 'Store enregistré avec succès',
      'Store Image URL': "URL de l'image du magasin",
      'Please log in via the mobile app':
         "Veuillez vous connecter via l'application mobile",
      'Edit Store Advertisement': 'Modifier la publicité du magasin',
      'ProductGroup Deleted Successfully': 'Groupe de produits supprimé avec succès',
      'Number Of Comments': 'Nombre de commentaires',
      'Number Of Likes': 'Nombre de Likes',
      'Please Enter Post Description': "Veuillez saisir la description de l'article",
      'Are You Sure You Want To Delete Posts':
         'Êtes-vous sûr de vouloir supprimer les messages',
      'Please enter Product Type': 'Veuillez entrer le type de produit',

      'Advertising Products': 'Produits publicitaires',
      'Add Advertisement': 'Ajouter des annonces',
      'Delete Ads': 'Supprimer les annonces',
      'Are you sure you want to delete Ads':
         'Êtes-vous sûr de vouloir supprimer les annonces',
      'Item Name': "Nom d'article",
      Photos: 'Photos',
      'Please enter price': "S'il vous plaît entrer le prix",
      Price: 'Prix de livraison minimum',
      'New password': 'nouveau mot de passe',
      'Post Saved Successfully': 'Poster enregistré avec succès',
      'Please enter IBAN': "S'il vous plaît entrer IBAN",
      'Please Enter BIC': "S'il vous plaît entrer BIC",
      'Please Enter Account Owner': "S'il vous plaît entrer le titulaire du compte",
      'Please Enter Account Address': "S'il vous plaît entrer l'adresse du compte",
      'An error occurred': 'Une erreur est survenue',
      'An error occurred while deleting':
         'Une erreur est survenue lors de la suppression',
      'Add Pictures :': 'Ajouter des images :',
      'Account Owner': 'Propriétaire du compte',
      'Item Description': "Description d'article",
      'Please enter item name': "S'il vous plaît entrer le nom de l'article",
      'Are you sure you want to Change Status of':
         'Êtes-vous sûr de vouloir changer le statut de ',
      'Are you sure you want to approve of': 'Êtes-vous sûr de vouloir approuver',
      'Are you sure you want to delete': 'Êtes-vous sûr que vous voulez supprimer ',
      'An error occurred while retrieving your payment details':
         "Une erreur s'est produite lors de la récupération des détails de votre paiement",
      'Please fill Delivery Price': "S'il vous plaît remplir DeliveryPrice",
      'After Saved Store Please, Go to backOffice User and assign Trader to Store for Enable store':
         "Après avoir sauvegardé le magasin, allez à l'utilisateur backOffice et assignez le commerçant au magasin pour activer le magasin.",
      'Advertisement Name': "Nom de l'annonce",
      'Please Enter Proper Store Link': 'Veuillez entrer le lien de magasin approprié',
      'Please enter Advertisement Url': "Veuillez saisir URL de l'annonce",
      'Please enter Advertisement Date': "Veuillez entrer la date de l'annonce",
      'Advertisement Url': 'URL de la publicité',
      'Store Status Updated Successfully Saved':
         'Statut du magasin mis à jour avec succès enregistré',
      'Please fill Minimum Delivery': "S'il vous plaît remplir la livraison minimale",
      'Please enter item description': "Veuillez entrer la description de l'article",
      'Mobile Number Should be 10 Digit':
         'Le numéro de téléphone portable devrait être 10 chiffres',
      Consumers: 'Consommateurs',
      'Add Users': 'Ajouter des utilisateurs',
      'Are You Sure You Want To Delete User':
         "Êtes-vous sûr de vouloir supprimer l'utilisateur?",
      'Delete User': "Supprimer l'utilisateur",
      Password: 'Mot de passe',
      'Confirm Password': 'Confirmer le mot de passe',
      'Please enter confirm password': 'Veuillez confirmer le mot de passe',
      'Please enter the same password': 'Veuillez saisir le même mot de passe',
      'Please enter password': 'Veuillez saisir le mot de passe',
      'Disable User': "Désactiver l'utilisateur",
      'Approve User': "Approuver l'utilisateur",
      'The user has not been approved': "L'utilisateur n'a pas été approuvé",
      Latitude: 'Latitude',
      Longitude: 'Longitude',
      'Select all': 'Tout sélectionner',
      'Unselect all': 'Tout désélectionner',
      Role: 'Rôle',
      'Enable Store': 'Activer le magasin',

      'Account Address': 'Adresse du compte',
      'Show In Flash Sale': 'Show In Flash Sale',
      Administrator: 'Administrateur',
      Trader: 'Négociant',
      'disable User': "désactiver l'utilisateur",
      'enable User': "Activer l'utilisateur",
      'Store Manager': 'Gérant de magasin',
      'Store Info': 'Info magasin',
      'Product Types': 'Types de produits',
      'Physical Store Does not exist': "Le magasin physique n'existe pas",
      'Select Manager': 'Sélectionner un gestionnaire',
      'User Disabled Successfully Saved': 'Utilisateur désactivé enregistré avec succès',
      'User Enabled Successfully Saved': 'Utilisateur activé enregistré avec succès',
      'Please select manager': "S'il vous plaît sélectionnez gestionnaire",
      'Password successfully reset': 'Mot de passe réinitialisé avec succès',
      'Add User': 'Ajouter un utilisateur',
      'Add Product': 'Ajouter un produit',
      'Add Store Advertisement': 'Ajouter une publicité au magasin',
      'Add Product Group': 'Ajouter un groupe de produits',
      'Select Product Type': 'Sélectionnez le type de produit',
      'Reset Password': 'réinitialiser le mot de passe',
      'Enable/Disable of User': "Activer/Désactiver l'utilisateur",
      'Please fill StoreLink': 'Veuillez remplir le lien de magasin',
      'Please Enter Advertisement Type': 'Veuillez entrer le type de publicité',
      Country: 'Pays',
      'Please select product group': 'Veuillez sélectionner un groupe de produits',
      'Please select product type': 'Veuillez sélectionner le type de produit',
      'Select Product Group': 'Sélectionner un groupe de produits',
      'Group Name': 'Nom du groupe',
      'Product Name': 'Nom du produit',
      'Total Quantity': 'Quantité totale',
      'Total Quantity Unit': 'Unité de quantité totale',
      'Available Quantity Unit': 'Unité de quantité disponible',
      'Check Address Tab': "Onglet Vérifier l'adresse",
      'Enter Store Image': 'Entrez Store Image',
      'Available Quantity': 'Quantité disponible',
      'Please enter available quantity': 'Veuillez saisir la quantité disponible',
      'Please enter total quantity': 'Veuillez saisir la quantité totale',
      'Please enter unit': `Veuillez entrer l'unité`,
      'Type Of Product / Price': 'Type de produit / prix',
      'Product short description': 'Description courte du produit',
      'Store Deleted Successfully': 'Store supprimé avec succès',
      'Product long description': 'Description longue du produit',
      'Please enter product long description':
         'Veuillez entrer une longue description du produit',
      'Please enter short description': 'Veuillez entrer une description courte',
      'Please enter group name': "S'il vous plaît entrer le nom du groupe",
      'Please enter product name': "S'il vous plaît entrer le nom du produit",
      'Please select store': 'Veuillez sélectionner un magasin',
      'Please select product quality': 'Veuillez sélectionner la qualité du produit',
      'Select Store': 'Sélectionner un magasin',
      'User Data Successfully Saved': 'Données utilisateur enregistrées avec succès',
      'User Profile Successfully Saved': 'Profil utilisateur enregistré avec succès',
      'Order ID': 'Numéro de commande',
      'Edit Users': 'Modifier les utilisateurs ',
      'Delete Account': 'Supprimer le compte',
      'Please enter email': "S'il vous plaît entrer une adresse email",
      'Phone Number': 'Numéro de téléphone',
      'Company Name': `Nom de l'entreprise`,
      'Download the group illustration photo':
         'Télécharger la photo d’illustration du groupe',
      'Date of Birth': 'Date de naissance',
      'Street Name': 'Nom de rue',
      Amount: 'Montant (€)',
      'Select Department': 'Sélectionner un département',
      Department: 'département',
      'Store URL': 'URL du magasin',
      'Is Physical Store ?': 'Est un magasin physique ?',
      'Department  Already exists': 'Département déjà sorti',
      'Please select department': 'Veuillez choisir un département',
      'Disable Store': 'Désactiver le magasin',
      'Enable/Disable Store': 'Activer/Désactiver le magasin',
      'Categories Stores': 'Catégories Commerces',
      'You will receive a reset password Link on your Mail ID':
         'Vous recevrez un lien de réinitialisation de mot de passe sur votre identifiant de messagerie',
      'Advertising Products Pictures': 'Photos de produits publicitaires',
      'Choose advertising Images': 'Choisissez des images publicitaires',
      'Principal Address': 'Adresse principale',
      'Other Address': 'Autre adresse',
      'Store Logo': 'Logo du magasin',
      'Company Logo': 'Logo du Entreprise',
      'Upload Profile Photo': 'Télécharger une photo du profil',
      'Postal Code': 'Code postal',
      'Buy Quantity From': 'Quantité initiale',
      'Buy Quantity To': 'Quantité cible',
      'Add Row': 'Ajouter une ligne',
      'Choose a Picture or Video': 'Choisissez une image ou une vidéo',
      'Types Of Products': 'Types de produits',
      'Clothing / Fashion': 'Habillement / mode',
      'Beauty / Health': ' Beauté / santé',
      'Advertisement Type': "Type d'annonce",
      'Product Successfully Saved': 'Produit sauvegardé avec succès',
      'Product Successfully Update': 'Mise à jour du produit réussie',
      'Sub-profile saved successfully.': 'Sous-profil enregistré avec succès.',
      'Sub-profile updated successfully.': 'Sous-profil mis à jour avec succès.',
      'The Product Picture field is required':
         'Le champ Image du produit est obligatoire.',
      'Cultural Products': 'Produits culturels',
      'House, decoration': 'Maison, décoration',
      'Travel / Tourism': 'Voyages / tourisme',
      'Games toys': ' Jeux, jouets',
      Organic: 'Organique',
      Shoes: 'Chaussures',
      Location: 'Emplacement',
      Inactive: 'Inactif',
      'Store Location': 'Emplacement du magasin',
      'Technical products / appliances': ' Produits techniques/électroménager',
      'Please Enter Store URL': "Veuillez entrer l'URL du magasin.",
      'Articles, sports equipment': ' Articles, matériels de sport',
      'User Deleted Successfully': 'Utilisateur supprimé avec succès',
      'Please select categories stores': 'Veuillez sélectionner des catégories',
      'Please select types of products': 'Veuillez sélectionner les types de produits',
      'Select Type': 'sélectionner un type',
      'Please select store logo': 'Veuillez choisir le logo du magasin',
      'Background photo': "Photo d'arrière-plan",
      'Please enter product amount': "S'il vous plaît entrer le montant du produit",
      'Please enter product promo price':
         "S'il vous plaît entrer le prix promo du produit",
      'Please enter product sell price': 'Veuillez entrer le prix de vente du produit',
      'Please select background photo': "Veuillez sélectionner une photo d'arrière-plan",
      'Please enter street name': 'Veuillez sélectionner le nom de la rue',
      'Promo Price Should be less than Sell Price':
         'Le prix promotionnel devrait être inférieur au prix de vente',
      'Please select date of birth': 'Veuillez sélectionner la date de naissance',
      'Please enter city': "S'il vous plaît entrer la ville",
      'Please enter postal code': "S'il vous plaît entrer le code postal",
      'Please enter mobile number..': 'Veuillez entrer le numéro de téléphone',
      'Please enter first name..': "S'il vous plaît entrer le prénom",
      'Please enter store name..': "S'il vous plaît entrer le nom du magasin",
      Presentation: 'Présentation',
      'Please enter store presentation..':
         'Veuillez entrer dans la présentation du magasin',
      'Are you sure you want to Enable/disable store':
         'Êtes-vous sûr de vouloir activer / désactiver le magasin',
      'Please enter last name..': "S'il vous plaît entrer le nom de famille",
      'Please select buildings..': 'Veuillez sélectionner des bâtiments',
      'Please select profile picture..': 'Veuillez sélectionner une photo de profil',
      'Choose a user profile picture..': 'choisissez une image de profil',
      'Choose a Group Profile': 'Choisir une Image de profil',
      'Password reset successfully': 'Mot de passe réinitialisé avec succès',
      'Drop file here..': 'Déposer le fichier ici',
      'Flash Sale': 'Vente flash',
      'Manager updated successfully': 'Gestionnaire mis à jour avec succès',
      'Manager saved successfully': 'Gestionnaire enregistré avec succès',
      'Manager deleted successfully': 'Gestionnaire supprimé avec succès',
      'Selected manager assigned somewhere':
         'Gestionnaire sélectionné attribué quelque part',
      'Are you sure you want to delete account':
         'Êtes-vous sûr de vouloir supprimer le compte',
      'First Name': 'Prénom',
      'Activity Description': `Description de l'activité`,
      'Needs Description': 'Description des besoins',
      'Last Name': 'Nom de famille',
      'PIN Code': 'Code PIN',
      'Home Page': "Page d'accueil",
      None: 'Aucun',
      none: 'aucun',
      'Product Picture': 'Image du produit',
      'Profile Picture': 'Image du profil',
      'Delete Store': 'Supprimer le magasin',
      Enabled: 'Activé',
      Disabled: 'Handicapés',
      'Please select product picture': 'Veuillez sélectionner la photo du produit',
      'Enter Actor Name': `Saisir le nom de l'acteur`,
      'Save Search': 'Sauvegarder la recherche',
      'Service Provider Name': 'Fournisseur de services',
      'Delivery Price': 'Prix de livraison',
      'Please enter delivery price': 'Veuillez entrer prix de livraison',
      'Show On Home Page?': "Afficher dans la page d'accueil ?",
      Active: 'Actif',
      Warning: 'Attention',
      'Order Items': 'Lancer la commande',
      'Shipping cost': 'Frais de port',
      'Please enter shipping cost': "S'il vous plaît entrer le coût d'expédition",
      'Category Type': 'Type de catégorie',
      'Store Status': 'Statut du magasin',
      'Product Image Deleted Successfully Saved':
         'Image du produit supprimée avec succès enregistrée',
      'Are you sure you want to delete Payment Details':
         'Êtes-vous sûr de vouloir supprimer les détails du paiement?',
      'Add Advertising Products Pictures': 'Ajouter des photos de produits publicitaires',
      'Please select category type': 'Veuillez sélectionner le type de catégorie',
      'Minimum Price For Delivery': 'Prix de livraison minimum',
      'Store Disabled Successfully Saved': 'Magasin désactivé enregistré avec succès',
      'Store Enabled Successfully Saved': 'Store activé et enregistré avec succès',
      'Please enter minimum price for delivery':
         "S'il vous plaît entrer le prix minimum pour la livraison",
      'Please enter response': "S'il vous plaît entrer la réponse",
      'Please select your service provider':
         'Veuillez sélectionner votre fournisseur de service',
      'Created By': 'Créé par',
      'Declared By': 'Déclaré par',
      'Updated By': 'Mis à jour par',
      'Check Details': 'Vérifier les info de la commande',
      'Edit Advertisement': 'Modifier la publicité',
      'Active Status': 'Statut actif',
      'Delivery Mode': 'Mode de livraison',
      'Created Date': 'Date de création',
      'Declared Date': 'Date déclarée',
      Response: 'Réponse',
      'Please select incident status': "Veuillez sélectionner le statut de l'incident",
      'Please enter comment': "S'il vous plaît entrer un commentaire",
      History: "L'histoire",
      Status: 'Statut',
      'Updated Date': 'Date mise à jour',
      Phone: 'Téléphone',
      'Delete Product': 'supprimer le produit',
      Date: 'Rendez-vous amoureux',
      'Order Details': 'détails de la commande',
      'Are you sure you want to Enable/Disable product':
         'Voulez-vous vraiment activer / désactiver le produit',
      'Disable/Enable Product': 'Désactiver / activer le produit',
      'Enable Product': 'Activer le produit',
      'Disable Product': 'Désactiver le produit',
      'Product Disabled Successfully Saved': 'Produit désactivé enregistré avec succès',
      'Product Enabled Successfully Saved': 'Produit activé enregistré avec succès',
      'Store Name': 'Nom du magasin',
      'Sub Profile Management': 'Gestion des sous-profils',
      'Add Payment Info': 'Ajouter des informations de paiement',
      'Delete Meeting': 'Supprimer la réunion',
      'Add Store': 'Ajouter un magasin',
      'View Store': 'Voir magasin',
      View: 'Voir',
      'Store Advertisement Successfully': 'Mémoriser la publicité avec succès',
      Reset: 'Réinitialiser',
      Copy: 'Copie',
      'Edit Store': 'Éditer le magasin',
      'Show In': 'Afficher dans',
      'Edit Product Groups': 'Modifier les groupes de produits',
      'Product Deleted Successfully': 'Produit supprimé avec succès',
      'Edit Product': 'Modifier le produit',
      'Store Already assigned to': 'Magasin déjà assigné à',
      Stores: 'Magasins',
      Agenda: 'Ordre du jour',
      Resolutions: 'Les résolutions',
      Remove: 'Retirer',
      'Order Delivery Address': 'Adresse de livraison de la commande',
      country: 'Pays',
      'Add Sub-profile': 'Ajouter un sous-profil',
      'Sub-profileName': 'Nom du sous-profil',
      'Please enter Sub-profileName': 'Veuillez saisir le nom du sous-profil',
      'Edit Sub-profile': 'Modifier le sous-profil',
      'Delete Sub-profile': 'Supprimer le sous-profil',
      'Are you sure you want to delete sub-profile':
         'Êtes-vous sûr de vouloir supprimer le sous-profil',
      'Sub-profileDeleted Successfully': 'Sous-profil supprimé avec succès',
      'Email id already exists': 'Identifiant email déjà sorti',
      'Data not inserted properly': 'Les données ne sont pas insérées correctement',
      'Password and confirm password does not match':
         'Mot de passe et confirmer le mot de passe ne correspond pas',
      'Please enter first name': "S'il vous plaît entrer le prénom",
      'Please enter last name': "S'il vous plaît entrer le nom de famille",
      'Token not found': 'Jeton non trouvé',
      'Please select user role': "Veuillez sélectionner un rôle d'utilisateur",
      'User not active': 'Utilisateur non actif',
      'Successfully Login!!!': 'Connectez-vous avec succès !!!',
      'Successfully Sign Up!!!': 'Inscrivez-vous avec succès !!!',
      'Please Enter Correct Username and Password ':
         "Veuillez entrer le nom d'utilisateur et le mot de passe corrects",
      'User id does not exist': "L'identifiant de l'utilisateur ne quitte pas",
      'Token Expired': 'Jeton expiré!',
      'Pending Approval': 'En attente de validation',
      'Email Sent. Check your inbox.': 'Email envoyé. Vérifiez votre boîte de réception.',
      'Email Does not Exists': "L'email n'existe pas",
      'Please enter a store name': "S'il vous plaît entrer le nom du magasin",
      'Please enter store description':
         "S'il vous plaît entrer la description du magasin",
      'Please enter store phone no': 'Veuillez entrer le numéro de téléphone du magasin',
      'Please enter store delivery price':
         'Veuillez saisir le prix de livraison en magasin',
      'Please enter store minimum for delivery':
         "S'il vous plaît entrer magasin minimum pour la livraison",
      'Product Group Successfully Saved': 'Groupe de produits enregistré avec succès',
      'Pay Online': 'Payez en ligne',
      'Pay later': 'Payez plus tard',
      'Partial payment': 'Paiement partiel',
      'An error occurred while deleting the user':
         "Une erreur s'est produite lors de la suppression de l'utilisateur",
      'enter IBAN': 'Entrez IBAN',
      'Please enter buy quantity from': 'Veuillez saisir la quantité achetée à partir de',
      'Please enter buy quantity to': 'Veuillez saisir la quantité achetée pour',
      Managers: 'Manageurs',
      Label: 'Libellé',
      Currencies: 'Devises',
      'Are you sure you want to delete the currency':
         ' Êtes-vous sûr de vouloir supprimer la devise',
      'Delete the currency': 'Supprimer la devise',
      'Reseller Sub-profile': 'Sous-profil revendeur',
      'Currency symbol': 'Symbole de la devise',
      'Add a currency': 'Ajouter une devise',
      Refuse: 'Refuser',
      Yes: 'Oui',
      'Please enter a currency': 'Veuillez entrer une devise',
      'Please enter a currency short name': 'Veuillez saisir un nom abrégé de la devise',
      'Please enter a symbol': 'Veuillez entrer un symbole',
      'Item successfully saved': 'Article enregistré avec succès',
      'An error occurred while retrieving sub-profiles':
         "Une erreur s'est produite lors de la récupération des sous-profils",
      'Item successfully updated': 'Mise à jour réussie',
      Total_Amount: 'Montant total (€)',
      Promo_Price: 'Prix promo(€)',
      Sell_Price: 'Prix de vente(€)',
      'Send Message': 'Envoyer Message',
      company: 'entreprise',
      individual: 'individuel',
      "User's image": "Image de l'utilisateur",
   },
};

const i18n = new VueI18n({
   locale: 'fr',
   fallbackLocale: 'en',
   messages,
});
export default i18n;
